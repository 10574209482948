
.App {
  text-align: center;
}

body {
  background: #F0F2F5;
}

.header {
  max-height: 60px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #01386A;
}

.header h1 {
  color: #ffffff;
}

input {
  background: #fafafa;
}

.login {
  margin-right: auto;
  margin-left: auto;
  display: block;
  padding-right: 10px;
  padding-left: 10px;
}

.logo {
  text-align: center;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 0;
}

.logo img {
  vertical-align: middle;
}

.login-form {
  max-width: 401px;
  margin: 50px auto;
  background: #fff;
  padding: 24px;
}

.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 300px;
}

.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.low-precision {
  color: #f71414;
}

input.low-precision {
  border-color: #f71414;
}

.low-precision,
.low-precision input {
  color: #f71414;
  border-color: #f71414;
}

.loadingFullPage {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

._loading_overlay_wrapper--active {
  z-index: 1001;
}

.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  max-width: 80%;
  font-size: 1.25rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
}

.inputfile {
  display: none;
}

.no-js .inputfile + label {
  display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

.inputfile-4 + label {
  color: #01386A;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
  color: #01386A;
}

.inputfile-4 + label figure {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #01386A;
  display: block;
  padding: 20px;
  margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
  background-color: #01386A;
}

.inputfile-4 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}

.list-items-search {
  height: 45vh;
  overflow: scroll;
}

.list-items-search .item-title {
  font-size: 12px;
  font-weight: 600;
  opacity: 0.8;
}

.list-items-search .item-image {
  width: 40px;
  height: 80px;
}

.list-items-search .item-btn-confirm span {
  font-size: 24px;
  color: #0316c1;
}

.list-items-search .item-btn-confirm-selected span {
  background: #0ad704;
  color: #FFF;
  line-height: 20px;
  border-radius: 50%;
  font-size: 28px;
}

.modal-search .ant-legacy-form-item {
  margin-bottom: 5px;
}

.modal-search .ant-modal-body {
  padding-top: 5px;
}

.modal-search .ant-select-selection-item {
  line-height: 14px;
  overflow: unset !important;
  white-space: unset !important;
  text-overflow: ellipsis;
}

.modal-search .loading, .modal-choose-customer .loading {
  display: flex;
  justify-content: center;
}


.item-group {
  display: flex;
  flex-direction: column;
}

.item-group span.suggestion {
  text-decoration: underline;
  cursor: pointer;
  color: #004f98;
}

.site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

.display-flex-row {
  display: flex;
  flex-direction: row;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
}

.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
}

.fw-600 {
  font-weight: 600;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #1A1A1A;
  color: white;
  font-size: 12px;
  text-align: center;
  padding: 12px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-top: 5px;
  opacity: 0;
  transition: opacity 0.3s;
  transform: translateX(-50%);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.low-precision-icon {
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}